
@import url('https://fonts.googleapis.com/css2?family=Anton&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
.about-container {
    display: flex;
    justify-content: start;
    align-items: start;
    flex-direction: row;
}

.about-side {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

/* .about-box {
    display: flex;
    justify-content: start;
    align-items: start;
    flex-direction: column;
    border: 3px solid black;
    width: 400px;
    text-align: start;
    padding-left: 15px;
    box-shadow: 8px 10px black;
    margin-top: 50px;
    cursor: pointer;
    transition: color 0.3s ease;
}

.about-title {
    font-family: "Anton", sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 2em;
    margin-bottom: 10px
}

.about-bot {
    margin-top: 10px;
    margin-bottom: 5px;
    font-weight: bold;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    width: 90%;
} */
/* 
.about-box {
    position: relative;
    display: flex;
    justify-content: start;
    align-items: start;
    flex-direction: column;
    border: 3px solid black;
    width: 400px;
    text-align: start;
    padding-left: 15px;
    box-shadow: 8px 10px black;
    margin-top: 50px;
    cursor: pointer;
    transition: color 0.3s ease;
    overflow: hidden;
  } */
  
  .about-title {
    font-family: "Anton", sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 2em;
    margin-bottom: 10px;
    position: relative;
    z-index: 2; /* Pour que le titre reste au-dessus de l'animation */
  }
  
  .about-bot {
    margin-top: 10px;
    margin-bottom: 5px;
    font-weight: bold;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    width: 90%;
    position: relative;
    z-index: 2; /* Même chose pour le bas de la boîte */
  }

  
  .about-box.hovered .color-overlay {
    top: 0; /* Fait glisser la couleur depuis le haut */
  }
  
  .about-desc {
    position: relative;
    z-index: 2; /* Pour que les descriptions soient toujours visibles */
    margin-bottom: 5px;
    font-size: 1em;
  }
  .about-box {
    position: relative;
    display: flex;
    justify-content: start;
    align-items: start;
    flex-direction: column;
    border: 3px solid black;
    width: 400px;
    text-align: start;
    padding-left: 15px;
    box-shadow: 8px 10px black;
    margin-top: 50px;
    cursor: pointer;
    transition: color 0.3s ease;
    overflow: hidden;
  }
  
  .about-title,
  .about-desc,
  .about-bot {
    transition: opacity 0.3s ease; /* Transition fluide pour le texte */
  }
  
  .color-overlay {
    position: absolute;
    top: -100%;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #bb9fea; /* Couleur au survol */
    transition: top 0.5s ease;
    z-index: 1;
  }
  
  .about-box.hovered .color-overlay {
    top: 0; /* Glisse vers le bas au survol */
  }
  
  .content {
    position: relative;
    z-index: 2; /* Toujours au-dessus de l'animation de couleur */
    transition: opacity 0.3s ease, transform 0.3s ease;
  }
  
  .content.show-text {
    opacity: 1;
    transform: translateY(0);
  }
  
  .content.hide-text {
    opacity: 0;
    transform: translateY(-10px); /* Fait disparaître le texte vers le haut */
  }
  
  .image-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    gap: 10px;
    opacity: 0;
    z-index: 2;
    transition: opacity 0.3s ease;
  }
  
  .image-container.show-images {
    opacity: 1;
  }
  
  .image-container.hide-images {
    opacity: 0;
  }
  
  .about-image {
    max-width: 100px;
    border-radius: 8px;
  }
  

