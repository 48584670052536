.mobile-about-title {
    font-family: "Anton", sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 1.2em;
    margin-bottom: 5vh;
    position: relative;
    z-index: 2; /* Pour que le titre reste au-dessus de l'animation */
  }
  
  .mobile-about-bot {
    margin-top: 10px;
    margin-bottom: 5px;
    font-weight: bold;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    width: 90%;
    position: relative;
    z-index: 2; /* Même chose pour le bas de la boîte */
  }

  
  .mobile-about-box.hovered .mobile-color-overlay {
    top: 0; /* Fait glisser la couleur depuis le haut */
  }
  
  .mobile-about-desc {
    position: relative;
    z-index: 2; /* Pour que les descriptions soient toujours visibles */
    margin-bottom: 5px;
    font-size: 0.8em;
  }
  .mobile-about-box {
    position: relative;
    display: flex;
    justify-content: start;
    align-items: start;
    flex-direction: column;
    border: 3px solid black;
    width: 80vw;
    text-align: start;
    padding-left: 10px;
    box-shadow: 8px 10px black;
    margin-top: 50px;
    cursor: pointer;
    transition: color 0.3s ease;
    overflow: hidden;
  }
  
  .mobile-about-title,
  .mobile-about-desc,
  .mobile-about-bot {
    transition: opacity 0.3s ease; /* Transition fluide pour le texte */
  }
  
  .mobile-color-overlay {
    position: absolute;
    top: -100%;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #bb9fea; /* Couleur au survol */
    transition: top 0.5s ease;
    z-index: 1;
  }
  
  .mobile-about-box.hovered .mobile-color-overlay {
    top: 0; /* Glisse vers le bas au survol */
  }
  
  .content {
    position: relative;
    z-index: 2; /* Toujours au-dessus de l'animation de couleur */
    transition: opacity 0.3s ease, transform 0.3s ease;
  }
  
  .mobile-content.show-text {
    opacity: 1;
    transform: translateY(0);
  }
  
  .mobile-content.hide-text {
    opacity: 0;
    transform: translateY(-10px); /* Fait disparaître le texte vers le haut */
  }
  
  .mobile-image-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    gap: 10px;
    opacity: 0;
    z-index: 2;
    transition: opacity 0.3s ease;
  }
  
  .mobile-image-container.show-images {
    opacity: 1;
  }
  
  .mobile-image-container.hide-images {
    opacity: 0;
  }
  
  .mobile-about-image {
    max-width: 20vw;
    border-radius: 8px;
  }