
.header-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    width: 1000px;
    height: 100px;
}

.header-links {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
}

.header-buttons {
    background: none;
    border: none;
    font-weight: bold;
    cursor: pointer;
    margin-left: 30px;
}

.header-buttons-selected {
    background: none;
    border: none;
    font-weight: bold;
    cursor: pointer;
    margin-left: 30px;
    color: #a47be6
}

.name {
    font-weight: bold;
}
