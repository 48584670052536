.mobile-header {
    display: flex;
    justify-content: end;
    align-items: center;
    flex-direction: row;
    width: 80vw;
    margin-top: 2vh;
    color: black
}

