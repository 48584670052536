.mobile-home-background {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.text-job {
    text-align: start;
    display: flex;
    justify-content: start;
    align-items: start;
    flex-direction: column;
}

.line-job {
    width: 10vw;
    border-bottom: 2px solid black;
    margin-right: 2vw;
}

.job-title {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
}

.mobile-socials {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    margin-top: 5vh;
    gap: 10vw
}

.skill-boxes-mobile {
    display: flex;
    justify-content: start;
    align-items: start;
    flex-direction: row;
    margin-top: 50px;
    transition: opacity 0.5s ease, transform 0.5s ease;
  }
  
  .skill-boxes-mobile.fade-in {
    opacity: 1;
    transform: translateY(0);
  }
  
  .skill-boxes-mobile.fade-out {
    opacity: 0;
    transform: translateY(20px); /* Déplacement vers le bas pour l'effet */
  }
  
  .skill-box-mobile {
    width: 20vw;
    height: 20vw;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border: 3px solid black;
    box-shadow: 8px 10px black;
    margin-right: 20px;
    box-shadow: 7px 6px rgba(0, 0, 0);
    transition: transform 0.3s ease;
  }
  
  .skill-box:hover {
    transform: scale(1.1); /* Effet au survol */
  }