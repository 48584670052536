.home-background {
    display: flex;
    justify-content: center;
    align-items: start;
    flex-direction: row;
    width: 1000px;
    margin-top: 30px;
}

.home-left {
    display: flex;
    justify-content: start;
    align-items: start;
    flex-direction: column;
    margin-right: 200px;
}

.home-left-title {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
}

.infos-buttons {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    margin-top: 20px;
}

.button-info {
    background-color: black;
    color: white;
    font-family: "Roboto", sans-serif;
    height: 50px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    width: 110px;
    font-weight: bold;
}

.skill-boxes {
    display: flex;
    justify-content: start;
    align-items: start;
    flex-direction: row;
    margin-top: 50px;
    transition: opacity 0.5s ease, transform 0.5s ease;
  }
  
  .skill-boxes.fade-in {
    opacity: 1;
    transform: translateY(0);
  }
  
  .skill-boxes.fade-out {
    opacity: 0;
    transform: translateY(20px); /* Déplacement vers le bas pour l'effet */
  }
  
  .skill-box {
    width: 100px;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border: 3px solid black;
    box-shadow: 8px 10px black;
    margin-right: 20px;
    box-shadow: 7px 6px rgba(0, 0, 0);
    transition: transform 0.3s ease;
  }
  
  .skill-box:hover {
    transform: scale(1.1); /* Effet au survol */
  }

  .socials {
    background: none;
    border: none;
    cursor: pointer;
    margin-right: 20px
  }
  
